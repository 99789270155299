// src/components/Navbar.js
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="text-white fixed w-full top-0 left-0 z-50 bg-black">
      <div className="flex justify-between items-center w-full px-4 py-4">
        <Link to="/" className="text-2xl font-bold">
          My Portfolio
        </Link>
        <button
          className="md:hidden focus:outline-none text-white"
          onClick={toggleMenu}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
        <div className="hidden md:flex space-x-4">
          <Link to="/" className="px-4 py-2">
            Home
          </Link>
          <Link to="/about" className="px-4 py-2">
            About
          </Link>
          <Link to="/portfolio" className="px-4 py-2">
            Projects
          </Link>
          <Link to="/skills" className="px-4 py-2">
            Skills
          </Link>
        </div>
      </div>
      {/* Mobile Menu */}
      <div className={`${isOpen ? "block" : "hidden"} md:hidden`}>
        <Link to="/" className="block px-4 py-2">
          Home
        </Link>
        <Link to="/about" className="block px-4 py-2">
          About
        </Link>
        <Link to="/portfolio" className="block px-4 py-2">
          Projects
        </Link>
        <Link to="/skills" className="block px-4 py-2">
          Skills
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
